<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      {{ item.keypair.name }}
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.keypair.fingerprint }}
      </div>
    </template>
    <template #more>
      <plain-button
        icon="delete"
        color="del"
        class="tariffs-table-item__more"
        @click="deleteSsh(item)"
      />
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import popoverFix from '@/mixins/popoverFix';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  name: 'TariffsTableSshRow',
  components: {
    BaseTableRow,
  },
  mixins: [popoverFix, showErrorOpenStackModal],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this.item.keypair.name;
    },
  },
  methods: {
    deleteCurrentSsh(name) {
      return this.$store.dispatch('moduleStack/deleteSsh', {
        name: name,
      });
    },
    deleteSsh() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('modal.sure.text', { msg: this.name }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentSsh(this.name)
                  .then(async data => {
                    // console.log(data);
                  })
                  .catch(e => this.showError(e));
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t('modal.sure.success');
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch('moduleStack/fetchOsKeypair');
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
        "delete": "Удалить SSH-ключ"
      },
      "noact": {
        "noactions": "Нет доступных действий.",
        "suspended": "Услуга остановлена администратором."
      },
      "sure": {
        "confirm": "Подтвердить"
      },
      "modal": {
        "sure": {
        "text": "Удалить SSH-ключ <b>%{msg}</b> ?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "success": "SSH-ключ успешно удалён.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.v-popover {
  text-align: center;
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__ip {
    //text-align: right;
  }

  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    text-align: center;
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;

      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
