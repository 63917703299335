<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="hover">
        {{ item.name }}
      </div>
      <div class="hover note-text">
        {{ item.description }}
      </div>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip">
        {{ item.id }}
      </div>
    </template>
    <template #config>
      <specs-server :specs="{ cpu: cpu, ram: ram, local: disk }"></specs-server>
    </template>
    <template #inst>
      <router-link
        v-if="attachToServer"
        :to="{ name: 'Server', params: { serverid: attachToServer.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ attachToServer.name }}
      </router-link>
    </template>
    <template #more>
      <div class="more">
        <Button
          type="linc"
          icon="pi pi-file-edit"
          class="p-button-rounded p-button-text"
          @click="changeFlavorDescription(item)"
        ></Button>
        <!--        <plain-button-->
        <!--          icon="edit"-->
        <!--          color="primary"-->
        <!--          class="tariffs-table-item__more"-->
        <!--          @click="changeFlavorDescription(item)"-->
        <!--        />-->
        <plain-button
          icon="delete"
          color="del"
          class="tariffs-table-item__more"
          @click="deleteFlavor(item)"
        />
      </div>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import popoverFix from '@/mixins/popoverFix';
import SpecsServer from '@/layouts/Stack/components/SpecsServer';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import changeFlavorDescription from '@/layouts/Stack/mixins/changeFlavorDescription';
import showErrorModal from '@/mixins/showErrorModal';
import Button from 'primevue/button';
export default {
  name: 'TariffsTableFlavorsRow',
  components: {
    BaseTableRow,
    SpecsServer,
    Button,
  },
  mixins: [popoverFix, changeFlavorDescription, showErrorModal],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cpu() {
      return this.item.vcpus ? this.item.vcpus : null;
    },
    ram() {
      return this.item.ram ? this.item.ram / 1024 : null;
    },
    disk() {
      return this.item.disk ? this.item.disk : null;
    },
    name() {
      return this.item.name;
    },
    attachToServer() {
      return this.$store.state.moduleStack.servers.find(
        server => server.flavor.original_name === this.item.name
      );
    },
  },
  methods: {
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    deleteCurrentFlavor() {
      return this.$store.dispatch('moduleStack/deleteFlavor', this.item.id);
    },
    deleteFlavor() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('modal.sure.text', { msg: this.name }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteCurrentFlavor(this.name)
                  .then(async () => {
                    // console.log(data);
                  })
                  .catch(e => this.showError(e));
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t('modal.sure.success');
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavors');
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
    isBootable(item) {
      return item.bootable === 'true' ? 'Загрузочный' : ' - ';
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ",
    "sure": {
      "confirm": "Выполнить"
    },
    "description": {
      "name": "Описание пользовательской конфигурации сервера:",
      "text" : "Текстовое описание",
      "title" : "Режим восстановления",
      "confirm": "Подтвердить",
      "rescue": "Запустить"
    },
    "modal": {
      "sure": {
        "text": "Удалить <b>%{msg}</b> конфигурацию?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "success": "Конфигурация успешно удалена.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 1.457rem;
}
.hover{
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
    }
  }
}
.more {
  display: flex;
  justify-content: flex-end
}
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__ip {
    //text-align: right;
  }

  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    text-align: center;
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;

      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
</style>
