<template>
  <div class="security-password">
    <div class="security-password__form" @keyup.enter="submit">
      <div v-for="item in fields" :key="item.key" class="security-password__field">
        <base-input
          :key="item.name"
          :ref="item.name"
          v-model="item.value"
          :tooltip="item.tooltip || null"
          :type="item.type"
          :name="item.name"
          :required="item.required"
          :custom-error-messages="
            customValidationMsgs[item.name] ? customValidationMsgs[item.name] : null
          "
          autocomplete="off"
          use-reactive-validation
          :pattern="item.name === 'confirm' ? formData.password : null"
          @change="onChange(item, $event.target.value)"
          @input="onInput"
        >
          <template v-if="item.name === 'password'" #icon>
            <plain-button icon="key" color="dark" tabindex="-1" @click="generatePass(item)" />
          </template>
          <template #fieldAfter>
            <plain-button
              :icon="item.type === 'text' ? 'eye-closed' : 'eye-open'"
              color="dark"
              tabindex="-1"
              @click="showPassword(item)"
            />
          </template>
          {{ $t(`fields.${item.name}.label`) }}
        </base-input>
      </div>
      <div class="security-password__footer">
        <base-button
          class="security-password__submit"
          :disabled="!isValid"
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('submit') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import mixin from '@/layouts/Profile/mixins/index';
import showErrorModal from '@/mixins/showErrorModal';
import generatePassword from '@/layouts/LoginPage/mixins/generatePasswd';
import store from '@/store';
export default {
  name: 'RecoverStackPassword',
  components: { BaseInput },
  mixins: [mixin, showErrorModal, generatePassword, billMgrToolActions],
  props: {
    recoveryInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      initialBackup: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('fields.confirm.patternMismatch') },
      },
      fields: [
        {
          name: 'password',
          type: 'password',
          invalid: false,
          value: '',
          tooltip: this.$t('fields.password.tooltip'),
        },
        {
          name: 'confirm',
          type: 'password',
          required: true,
          value: '',
          invalid: false,
          tooltip: this.$t('fields.confirm.tooltip'),
        },
      ],
      isReady: false,
      isLoading: false,
    };
  },
  computed: {
    id() {
      return this.$store.state.moduleStack.current;
    },
    isValid() {
      // return this.fields[0].value && this.fields[0].value === this.fields[1].value;
      return this.fields[0].value && this.formData.password === this.formData.confirm;
    },
  },
  watch: {
    fields(val) {
      this.setInitialFormData(val);
    },
    isReady(val) {
      this.$emit('ready-state-change', val);
    },
    hasChanges(val) {
      this.$emit('form-data-change', val);
    },
  },
  mounted() {
    if (this.fields.length) this.setInitialFormData(this.fields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    onInput() {
      this.ifGenerate = false;
    },
    onChange(key, value) {
      this.formData[key.name] = value;
      if (this.ifGenerate) {
        this.formData.confirm = this.formData.password;
      }
    },
    showPassword(item) {
      if (item.type === 'password') {
        item.type = 'text';
      } else {
        item.type = 'password';
      }
    },
    //
    async sendBillMgrAction(params) {
      return store.dispatch('sendBillMgrToolActionRecover', params);
    },
    setInitialFormData(list) {
      this.initialBackup = '';
      this.formData = list.reduce((acc, item) => {
        acc[item.name] = item.value;
        this.initialBackup += item.value;
        return acc;
      }, {});
      this.isReady = true;
    },
    submit(e) {
      e.preventDefault();
      if (this.isValid) {
        this.isLoading = true;
        const params = {
          clicked_button: 'on',
          sok: 'ok',
          passwd: this.formData.password,
          confirm: this.formData.confirm,
          func: 'service.changepassword',
          elid: this.id,
          auth: localStorage.getItem('token'),
          out: 'bjson',
        };
        this.sendBillMgrAction(params)
          .then(async data => {
            // console.log(data);
            if (data && data.ok) {
              await this.$store.dispatch('moduleStack/fetchList');
              await setTimeout(
                () => this.$store.dispatch('moduleStack/fetchDetail', this.id),
                1500
              );
              this.clear();
              this.showSuccess(this.$t('success'));
            }
          })
          .catch(e => this.showError(e))
          .finally(() => (this.isLoading = false));
      }
    },
    showSuccess(msg) {
      this.$modals.open({
        name: 'Success',
        // title: this.$t('success'),
        text: msg,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    clear() {
      this.formData = {};
      this.initialBackup = '';
      this.setInitialFormData(this.fields);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "fields": {
        "password": {
          "label": "Новый пароль",
          "tooltip": "Сгенерировать надежный пароль"
        },
        "confirm": {
          "label": "Подтверждение пароля",
          "tooltip": "Подтверждение пароля",
          "patternMismatch": "Пароли не совпадают"
        }
      },
      "submit": "Обновить пароль",
      "ok": "Понятно",
      "error": "Ошибка",
      "success": "Ваш пароль успешно узменен."
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.security-password {

  &__form {
    +breakpoint(sm-and-up) {
      flexy(flex-start, flex-end, wrap);
    }
    +breakpoint(md-and-up) {
      flex-wrap: nowrap;
      padding-bottom: 1rem;
    }
  }

  &__field {
    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
    }

    & + & {
      margin-top: 1.25rem;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }

  //&__submit {
  //  width: 100%;
  //  text-align: center;
  //  white-space: nowrap;
  //  margin-top: 2.5rem;
  //  min-width: 165px;
  //
  //}
  &__footer {
    margin-top: 2.5rem;
    margin-left: 1rem;
    margin-bottom: 0.6em;
    text-align: center;

    +breakpoint(sm-and-down) {
      margin-left: 0;
      margin-top: 0.25rem;
      text-align: left;
    }
  }
  &__submit {
    min-width: 165px;
  }
}
</style>
