<template>
  <div class="tariffs-table">
    <base-table :columns="tableHead" :list="paginList" class="tariffs-table__table">
      <tariffs-table-ssh-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="osKeyPair.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableSshRow from './TariffsTableSshRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableSsh',
  components: {
    BaseTable,
    TariffsTableSshRow,
    NewPagination,
  },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '300px',
            maxWidth: '350px',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '200px',
            maxWidth: '210px',
            // paddingRight: '220px',
            // textAlign: 'right',
          },
        },
        {
          // label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '24px',
            textAlign: 'center',
            maxWidth: '24px',
          },
        },
      ],
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    osKeyPair() {
      return this.$store.state.moduleStack.keypairs;
    },
    // list() {
    //   return this.$store.state.moduleStack.servers;
    // },
    // disks() {
    //   return this.$store.state.moduleStack.volumes;
    // },
    // images() {
    //   return this.$store.state.moduleStack.privateImages;
    // },
    // sort() {
    //   return this.$store.state.moduleStack.sort;
    // },
    paginList() {
      //if (this.pagin.step === 'all') return this.osKeyPair;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.osKeyPair.slice(start, end);
    },
  },
  /*methods: {
    setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },
  },*/
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя",
      "id": "Fingerprint",
      "menu": "Меню"
    },
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
